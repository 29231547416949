<template>
  <div class="row">
    <div :class="{'col-lg-8': !propAppId, 'col-lg-12': propAppId}">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
        <div class="iq-card-body">
          <div class="image-block">
            <img :src="quizDetail.app_image" class="img-fluid rounded m-auto d-block" alt="blog-img">
          </div>
          <div class="blog-description mt-2">
            <b-badge
              class="border mr-2 mb-2 font-weight-normal"
              variant="primary"
              style="font-size: 11px; font-weight: bold !important;"
            >
              {{quizDetail.app_is_gideplus == 0 ? 'Gide' : 'Gide Plus'}}
            </b-badge>
            <h5 class="row mb-3 pb-3 border-bottom">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-cl-8">
                {{quizDetail.app_name}}
                <section class="ml-0 mb-2">
                <b-badge class="mr-2 mb-2" style="white-space: normal;">
                  {{quizDetail.app_category}}
                </b-badge>
                <div style="display: inline;" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'">
                  <b-badge v-if="quizDetail.deleted == 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                    Published&nbsp;
                  </b-badge>
                  <b-badge v-else-if="quizDetail.deleted == 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                    Unpublished
                  </b-badge>
                  <b-badge v-else  class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                    Deleted
                  </b-badge>
                </div>
              </section>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-cl-4">
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="pull-right mr-2 mb-2" variant="primary" size="sm" @click="goToQuestions(`/quizBank_list/${quizDetail.app_id}`)">Question Bank</b-btn>
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="pull-right mr-2 mb-2" variant="primary" size="sm" @click="goToUrl(`/quiz_edit/${quizDetail.app_id}`)">Edit</b-btn>
                <b-btn v-if="quizDetail.app_is_gideplus == 0 || (quizDetail.app_is_gideplus == 1 && gidePlusData.subs_id)"
                  :disabled="quizDetail.app_type === Config.QUIZ_TYPE.competition &&
                            (quizDetail.app_date_ts > currentTs || quizDetail.aprs_id && quizDetail.aprs_id.length > 1)
                            || quizDetail.app_type === Config.QUIZ_TYPE.competition && quizDetail.app_date_ts
                            && currentTs > (quizDetail.app_date_ts + quizDetail.app_duration)"
                  class="pull-right mr-2 mb-2" variant="primary" size="sm" @click="startQuiz(quizDetail.app_id)"
                  >
                    {{ quizDetail.app_type === Config.QUIZ_TYPE.competition && quizDetail.app_date_ts && quizDetail.app_date_ts > currentTs ? 'Quiz not started yet' :
                      quizDetail.app_type === Config.QUIZ_TYPE.competition && quizDetail.app_date_ts && currentTs > (quizDetail.app_date_ts + quizDetail.app_duration) ? 'Quiz Expired' : ( quizDetail.app_type === Config.QUIZ_TYPE.competition && quizDetail.aprs_id && quizDetail.aprs_id.length > 1 ) ? 'Already participated' :  'Start now'
                    }}
                </b-btn>
                <b-btn v-else class="pull-right mr-2" variant="warning" size="sm" @click="clickToUpgrade()">
                  {{ "Become a Student Club Member to Start" }}
                </b-btn>
              </div>
              <section class="ml-2" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" style="">
                <b-button variant="light mr-1 mb-1 pl-0" title="Unpublish" @click="appPublishUnpublish(quizDetail)">
                  <i class="fa fa-toggle-off m-0 font-size-20" style="color:red"></i>
                </b-button>
                <b-button variant="light mr-1 mb-1" title="Publish" @click="appPublishUnpublish(quizDetail)">
                  <i class="fa fa-toggle-on m-0 font-size-20" style="color:green"></i>
                </b-button>
                <b-button variant=" mr-1 mb-1 " @click="showQuizDeleteDialog(quizDetail)" title="Delete"><i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i></b-button>
                <b-button variant=" mr-1 mb-1 " @click="appResultList(quizDetail)" title="Results"><i class="fa fa-star font-size-20" aria-hidden="true"></i></b-button>
                <b-button variant=" mr-1 mb-1 " @click="syncResult(quizDetail)" title="Sync Result"><i class="fa fa-refresh font-size-20" aria-hidden="true"></i></b-button>
              </section>
              <b-modal v-model="showModelQuizDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
                <p>{{cvDelMessage}}</p>
                <template #modal-footer="">
                  <!-- Emulate built in modal footer ok and cancel button actions -->
                  <b-button size="sm" @click="showModelQuizDelete = false">
                    {{cvbtnModalCancel}}
                  </b-button>
                  <b-button size="sm" variant="primary" @click="appDelete()">
                    {{cvbtnModalDelOk}}
                  </b-button>
                </template>
              </b-modal>
            </h5>
            <div class="row blog-meta d-flex align-items-center mb-3">
            <div class="col-12 date" v-if="quizDetail.app_date_ts">
                <i class="ri-calendar-2-fill text-primary pr-2"></i>
                {{quizDetail.app_date_ts | dateFormatDDMMYYYYHHMMA}}
              </div>
            </div>
            <div class="" v-if="quizDetail.has_timer">
              <span class="mr-2">Quiz Duration:</span>
              <b-badge
                class="border mr-2 mb-2 font-weight-normal"
                variant="none">
                <span>
                  {{quizDetail.app_duration | durationToDHM}}
                </span>
              </b-badge>
            </div>
            <p class="vhtmlTag vueHtmlParent mt-3 vhtmlUlLi" v-html="quizDetail.app_description">
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 gide_right_mobile_data" v-if="!isMobileDevice && !propAppId">
      <div class="iq-card iq-card-block iq-card-stretch blog-post">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="iq-card-title">
              Upcoming Quiz
            </h4>
          </div>
        </div>
        <hr />
        <div class="iq-card-body" v-if="examList && examList.length > 0">
          <ul class="list-inline p-0 mb-0 mt-2">
            <li class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mb-3 pointer" v-for="(quiz, index) in examList" :key="index+'QiozLIST'">
              <div class="d-flex align-items-top pb-3 border-bottom" @click="QuizDetail(quiz.app_id)">
                <div class="col-md-5">
                  <div class="image-block" style="height: 80px;" v-if="quiz.app_image">
                    <img :src="quiz.app_image" class="img-fluid rounded w-100" style="height: 80px;" alt="blog-img">
                  </div>
                  <div v-else style="background-color: #EEEEEE; width: 100%; height: 80px; border-radius: 5px; display: flex; text-align: center;">
                    <span style="margin: auto; font-size: 36px;">{{ quiz.app_name.slice(0, 1) }}</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="blog-description pl-2">
                    <div class="date mb-1">
                      <a  style="cursor: pointer;" tabindex="-1">
                        {{quiz.app_date_ts | dateFormatDDMMYYYYHHMMA}}
                      </a>
                    </div>
                    <h6 class="mb-2">
                      {{ quiz.app_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="iq-card-body" v-else>New Quiz coming soon...</div>
      </div>
      <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)" />
    </div>

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>

    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc"
    :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToUrl(quizDetail.module_payment_page)"/>
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import AlertBox from "../../../components/AlertBox.vue"
import EventHomeList from "../../../views/Gide/Event/EventHomeList.vue"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"
import { Apps } from "../../../FackApi/api/apps.js"
import Config from "../../../Utils/config.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import moment from "moment"
import { AppResults } from "../../../FackApi/api/appResults"

export default {
  name: "EventView",
  components: {
    AlertBox,
    EventHomeList
  },
  data () {
    return {
      apiName: "app_view",
      cvTitle: "Quiz",
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      quizId: null,
      quizDetail: {},
      toastVariant: "",
      showToast: false,
      cvDelMessage: "Are You Sure?",
      cvbtnModalCancel: "Cancel",
      cvbtnModalDelOk: "Delete",
      delObj: {},
      toastMsg: "",
      showModelQuizDelete: false,
      toastTitle: "Quiz",
      cvLoadingStatus: false,
      showUpgradeAlertBox: false,
      alertDesc: "This is a paid activity. Please register and complete your payment to activate this activity",
      alertTitle: "Quiz",
      okText: "Register",
      cancelText: "Not now",
      utility: Utility,
      showPaymentModal: false,
      modName: "quiz", // Module name,
      currentTs: moment().utc().valueOf() / 1000,
      examList: [],
      Config: Config,
      gide_right_mobile_data: true,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList()
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    gidePlusData () {
      return this.$store.getters["Paygw/myPlanState"]
    }
  },
  props: {
    propAppId: {
      type: String,
      default: null
    },
    propParentComponentName: {
      type: String,
      default: null
    }
  },
  watch: {
    "$route.params.quizId": {
      handler: function (ev) {
        this.loadRequiredData()
      }
    }
  },
  mounted () {
    if (navigator.userAgent.toLowerCase().includes("iphone") ||
      navigator.userAgent.toLowerCase().includes("android")
    ) {
      this.gide_right_mobile_data = false
    }

    this.loadRequiredData()
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      socialvue.index()
      this.quizId = this.$route.params.quizId
      if (!this.quizId) {
        this.quizId = this.propAppId
      }
      this.appView()
    },
    /**
     * getExamList
    */
    async getExamList (type) {
      let payload = {
        filter: JSON.stringify({ limit: 5, type: type }),
        upcoming: 1
      }
      const quizListResp = await Apps.appList(this, payload)
      if (!quizListResp.resp_status) {
        return
      }
      this.examList = quizListResp.resp_data.data
    },
    /**
     * goToQuestions
     */
    goToQuestions (url) {
      window.open(url, "_blank")
    },
    /**
     * goToUrl
     */
    goToUrl (url) {
      if (this.quizDetail.app_type == Config.QUIZ_TYPE.competition) {
        this.$router.push(`/event/${this.quizDetail.app_id}`)
      }
      else if (url && url.length) {
        window.open(url, "_blank")
      }
      else if (this.quizDetail.app_type == Config.QUIZ_TYPE.testseries) {
        this.$router.push(`/test_prep_product`)
      }
    },
    /**
     * appDelete
     */
    async appDelete () {
      try {
        let quizDelResp = await Apps.appDelete(this, this.delObj.app_id)
        await ApiResponse.responseMessageDisplay(this, quizDelResp)

        if (quizDelResp && !quizDelResp) {
          this.showModelQuizDelete = false
          return false
        }
        this.showModelQuizDelete = false
        this.quizDetail.deleted = 1
      }
      catch (err) {
        console.error("Exception occurred at appDelete() and Exception:", err.message)
      }
    },
    /**
     * Quiz View
     */
    async appView () {
      const quizResp = await Apps.appView(this, this.quizId)
      if (!quizResp.resp_status || !quizResp.resp_data.data.app_id) {
        this.$router.push(`/quiz`)
      }

      this.quizDetail = quizResp.resp_data.data
      if (!this.isMobileDevice) {
        this.getExamList(this.quizDetail.app_type)
      }
    },
    /**
     * showQuizDeleteDialog
     */
    showQuizDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelQuizDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showQuizDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * QuizDetail
    */
    async QuizDetail (quizId) {
      this.$router.push(`/quiz_detail/${quizId}`)
    },
    /**
     * appResultList
     */
    appResultList (app) {
      this.$router.push(`/appResult_list/${app.app_id}`)
    },
    /**
     * syncResult
     */
    async syncResult (examItem) {
      const resultSyncResp = await AppResults.appResultSync(this, examItem.app_id)
      if (!resultSyncResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, resultSyncResp)
      }
    },
    /**
     * startQuiz
    */
    async startQuiz (examId) {
      if (this.quizDetail.app_type === Config.QUIZ_TYPE.competition && this.quizDetail.user_registered !== 1 && this.quizDetail.app_is_paid === 1) {
        this.showUpgradeAlertBox = true
      }
      else if (this.quizDetail.app_type === Config.QUIZ_TYPE.testseries && this.quizDetail.user_registered !== 1 && this.quizDetail.app_is_paid === 1) {
        this.alertDesc = "This quiz is a part of GIDE's TestSeries Product, Kindly purchase the GIDE's TestSeries product to access this"
        this.okText = "Buy Now"
        this.showUpgradeAlertBox = true
      }
      else {
        this.$router.push(`/question_quiz/${examId}`)
      }
    },
    /**
     * clickToUpgrade
     */
    async clickToUpgrade () {
      this.$router.push(`/subscription_plans`)
    },
    /**
     * appPublishUnpublish
     */
    async appPublishUnpublish (data) {
      let payload = {
        app_id: data.app_id,
        deleted: null,
        image: data.app_image,
        desc: data.app_description,
        title: data.app_name,
        app_type: data.app_type,
        app_date_ts: data.app_date_ts,
        app_duration: data.app_duration
      }

      if (data.deleted === 0) {
        // event is published then unpublish
        payload.deleted = 2
      }
      else if (data.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      const questionResp = await Apps.appPublishUnpublish(this, payload)
      if (questionResp && questionResp.resp_status) {
        data.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, questionResp)
      }
    }
  }
}
</script>
